function transformUser(obj = {}) {
    const { applicationRoles, email, displayName, name, osuid, given_name = '', family_name = '' } = obj

    const formattedUser = {
        applicationRoles: applicationRoles,
        email: email,
        firstName: given_name,
        fullName: `${name} ${family_name}`,
        initials: `${given_name && given_name.charAt(0)}${family_name && family_name.charAt(0)}`.toUpperCase(),
        lastName: family_name,
        name: displayName ? displayName : name,
        osuid: osuid
    }

    return formattedUser
}

export { transformUser }