import { Mui } from "@osu/react-ui";
const disabledGray = "rgba(255, 255, 255, 0.65)"
const darkGray = "#4A4A4A"
const lightGray = "#E6E6E6"

const useStyles = Mui.makeStyles((theme) => {
    const darkMode = theme.palette.type === "dark"
    return {
        card: {
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 25rem)",
            paddingBottom: "2rem",
            paddingLeft: "4rem",
            paddingRight: "4rem",
            paddingTop: "2rem",
            [theme.breakpoints.down("md")]: {
                paddingLeft: "2rem",
                paddingRight: "2rem"
            }
        },
        fileUploader: {
            paddingTop: "0.5rem",
            "& div[role='alert']": {
                marginTop: "0.5rem",
                maxWidth: "fit-content"
            }
        },
        formControl: {
            marginBottom: "1rem",
            marginTop: "0.5rem",
            width: "100%"
        },
        help: {
            border: "solid 0.15rem",
            borderColor: theme.palette.primary.main,
            borderRadius: "0.5rem",
            marginBottom: "1rem",
            [theme.breakpoints.down(470)]: {
                flexWrap: "wrap",
                justifyContent: "center"
            }
        },
        helpIcon: {
            alignSelf: "center",
            display: "flex",
            paddingRight: "1rem"
        },
        logoutButton: {
            textTransform: "none"
        },
        noLoginButton: {
            marginTop: "0.5rem",
            width: "25rem",
            [theme.breakpoints.down(470)]: {
                width: "10rem"
            }
        },
        osuLoginButton: {
            marginTop: "1rem",
            width: "25rem",
            [theme.breakpoints.down(470)]: {
                width: "10rem"
            }
        },
        status: {
            minHeight: "10rem"
        },
        bold: {
            fontWeight: "bold"
        },
        smallText: {
            fontSize: "0.9rem"
        },
        selectLabel: ({ elevation }) => ({
            zIndex: theme.zIndex.mobileStepper,
            background: darkMode ? ` var(--elevation${elevation || 0})` : theme.palette.background.paper
        }),
        userName: {
            alignItems: "center",
            display: "flex"
        },
        completed: {
            color: `${theme.palette.success.main} !important`
        },
        progress: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        mobileStepper: {
            margin: `${theme.spacing(2)}px 0`,
            [theme.breakpoints.down(375.5)]: {
                flexDirection: "column-reverse",
            }
        },
        stepperProgress: {
            [theme.breakpoints.down(375.5)]: {
                alignSelf: "center",
                marginTop: theme.spacing()
            }
        },
        checkCircle: {
            width: "4rem",
            height: "4rem",
            color: theme.palette.success.main
        },
        errorCircle: {
            width: "4rem",
            height: "4rem",
            color: theme.palette.error.main
        },
        errorList: {
            marginBottom: "0",
            marginTop: "0.5rem"
        },
        textField: ({ readOnly }) => {
            if(readOnly) {
                return {
                    backgroundColor: darkMode ? darkGray : lightGray,
                    color: darkMode ? lightGray : darkGray,
                    borderRadius: '4px'
                }
            }
        },
        disabledButton: {
            fontWeight: "300"
        },
        activeLink: {
            color: "inherit",
            fontWeight: "400"
        },
        fontWeightNormal: {
            fontWeight: "normal"
        },
        buttonLabelWhite: {
            color: theme.palette.text.primary
        },
        buttonLabelDisabled: {
            fontWeight: "300",
            color: darkMode ? disabledGray : darkGray
        },
        outlineCircle: {
            color: 'transparent',
            borderRadius: '100%',
            border: `solid 1px ${darkMode ? disabledGray : theme.palette.text.primary}`
        },
        outlineCircleText: {
            color: darkMode ? disabledGray : theme.palette.text.primary,
            fill: darkMode ? disabledGray : theme.palette.text.primary
        }
    };
}, { index: 99 });

export default useStyles;