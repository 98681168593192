import { transformUser } from "./transform";
import { buildAction, getGraphQLAuthMode } from "../util/functions";
import * as Queries from "../graphql/queries";
import { CLEAR_USER_OPTIONS, SET_USER, USER_STATUS, UPDATE_USER_OPTIONS } from "../actions-index";
import { ACTION_STATUS } from "../util/constants";
import { API } from "aws-amplify";

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: Object.keys(user || {}).length > 0 ? transformUser(user) : null
    }
}

export function setUserStatus(status) {
    return {
        type: USER_STATUS,
        payload: status
    }
}

export function clearFormControlOptions() {
    return dispatch => dispatch(buildAction(CLEAR_USER_OPTIONS, {
        data: {
            controlFormOptions: {
                audiences: []
            }
        }
    }));
}

export function loadFormControlOptions(emplid) {
    return dispatch => dispatch(buildAction(UPDATE_USER_OPTIONS, {
        progress: 50,
        status: ACTION_STATUS.LOADING,
        [emplid]: {
            status: ACTION_STATUS.LOADING,
        }
    }));
}

export function buildFormControlOptions(emplid, controlFormOptions) {
    return dispatch => dispatch(buildAction(UPDATE_USER_OPTIONS, {
        progress: 100, 
        status: ACTION_STATUS.SUCCESS,
        [emplid]: {
            audiences: controlFormOptions?.audiences,
        }, 
        audiences: controlFormOptions 
    }));
}

export function getFormControlOptions(emplid) {
    return async (dispatch, getState) => {
        let baseState =  {
            data: {
                controlFormOptions: {
                    audiences: []
                }
            }
        }
        const state = getState()
        const existingAudienceState = state?.audiences
        if(existingAudienceState[emplid]) {
            const currentResult = existingAudienceState[emplid]?.audiences
            dispatch(buildFormControlOptions(emplid, {
                audiences: currentResult
            }))  
            baseState.data.controlFormOptions.audiences = currentResult
            return baseState
        }
        dispatch(loadFormControlOptions(emplid));
        const res = await API.graphql({
            authMode: getGraphQLAuthMode(state),  
            query: Queries.getFormControlOptions, 
            variables: { emplid: emplid } 
        });
        dispatch(buildFormControlOptions(emplid, res.data.controlFormOptions))    
        return Object.assign(baseState, res)
    }
}

export function receiveAmplifyHubUser(response = {}) {
    return async function (dispatch) {
        const osuid = response?.osuid || "no-user"
        if(response?.status) {
            dispatch(setUserStatus(response?.status));
            dispatch(getFormControlOptions(osuid ))
        }
        if(['error', 'cleared'].includes(response?.status)) {
            dispatch(recievedSetUser({}));
            dispatch(getFormControlOptions( "no-user" ))

        } else if(response?.osuid || response?.displayName) {
            dispatch(recievedSetUser(response));
            dispatch(clearFormControlOptions()); // clear form control options so that previous options are not used
            dispatch(getFormControlOptions(osuid))
        } 
    }
}

