const _getConfig = `
    getConfig(unit: $unit) {
        config {
            assignmentGroup
            attachmentAllowed
            constituentAudience
            helpText
            helpTextId
            id
            salesforceRoutingId
            service
            sortOrder
            subtopic
            topic
            unit
            uServiceProvider
        }
        lastModified
        status
    }
`
export const getConfig = `
    query GetConfig($unit: String) {
        ${_getConfig}
    }
`;

export const getSignedUploadUrl = `
    query GetSignedUploadUrl($name: String!) {
        getSignedUploadUrl(name: $name) {
            s3Object
            signedUrl
            lastModified
            status
        }
    }
`;

const _controlFormOptions = `
    controlFormOptions(emplid: $emplid) {
        audiences
    }
`

export const getFormControlOptions = `
    query getFormControlOptions($emplid: String!) {
        ${_controlFormOptions}
    }
`;

export const combinedFormControlAndConfig = `
    query combinedFormControlAndConfig($emplid: String!, $unit: String) {
        ${_getConfig}
        ${_controlFormOptions}
    }
`