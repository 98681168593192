import { CONFIG } from "../actions-index";
import { ACTION_STATUS } from "../util/constants";

export function config(state = {}, action = {}) {
    let defaultState = {
        formInputState: {
            config: {},
            attachments: {}
        }
    }
    Object.assign(defaultState, state)
    let payload = {
        ...action?.payload ?? {},
    }
    if(action?.payload?.status) {
        payload.status = action?.payload?.status
    } else if (action?.payload?.data?.length) {
        payload.status = ACTION_STATUS.SUCCESS
    } else {
        payload.status = ACTION_STATUS.ERROR
    }
    return (action.type === CONFIG ? payload : defaultState);
}