import React, { useEffect, useState } from "react";
import { Mui } from "@osu/react-ui";
import { compact, values as _values } from "lodash";
import { getUpdatedStepStateValues } from "../../steps";
import useStyles from "../../styles";

const MAX_LENGTH_EMAIL = 80;
const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function ContactInformation(props = {}) {
    const { stepKey, stepState, updateStepState, user, elevation } = props;
    const { values } = stepState;
    const { email = "", firstName = "", lastName = "" } = values;
    const [validation, setValidation] = useState({});
    const readOnly = !!user
    const classes = useStyles({ elevation, readOnly });

    const validateText = (value) => {
        return !!value;
    }
    const validateEmail = (email) => {
        return (String(email).toLowerCase().match(REGEX_EMAIL) !== null && email.length <= MAX_LENGTH_EMAIL);
    };

    const updateState = (fieldName, fieldValue, parentFieldName = null) => {
        const updatedValues = getUpdatedStepStateValues(stepState, fieldName, fieldValue, parentFieldName = null);
        updateStepState(stepKey, { ...stepState, values: updatedValues });
    };

    /*
        When the validation changes, update isComplete.
        Applies only when there is no user.
        When there is a user, isComplete is automatically set to true when the Contact Information is updated with the user information.
    */
    useEffect(() => {
        const requiredFields = [stepState?.values?.firstName, stepState?.values?.lastName, stepState?.values?.email];
        const validationFlags = _values(validation);
        const isFormComplete = (
            compact(requiredFields).length === requiredFields.length && // has all required fields
            validationFlags.every(validationFlag => (validationFlag === true)) // all validation flags are true
        );
        if(!user && stepState.isComplete !== isFormComplete) updateStepState(stepKey, { ...stepState, isComplete: isFormComplete });
    }, [validation, stepKey, stepState, updateStepState, user]);

    const readOnlyDescriptorId = readOnly ? "read-only-contact-info-group-description" : ""
    const reviewId = "review-info-text"

    return (
        <Mui.Box marginTop="0.5rem">
            {readOnly && <Mui.Typography id={reviewId} variant="body2" color="secondary">Review your information. Updates can be made at
                {" "}{<Mui.Link target="_blank" rel="noopener" href="https://my.osu.edu">my.osu.edu</Mui.Link>}.{" "}
            </Mui.Typography>}
            <Mui.FormControl variant="outlined" required={!readOnly} className={classes.formControl}>
                <Mui.FormLabel id="firstNameLabel" required={!readOnly}>First Name</Mui.FormLabel>
                <Mui.TextField className={classes.textField} autoFocus variant="outlined" aria-required={!readOnly} value={firstName} readOnly={!!user} FormHelperTextProps={{ id: "firstNameHelperText" }}
                    inputProps={{
                        "aria-describedby": "firstNameHelperText " + (readOnlyDescriptorId) + ` ${reviewId}`,
                        "aria-invalid": validation?.firstName === false,
                        "aria-labelledby": "firstNameLabel",
                        "autoComplete": "given-name",
                        "aria-readonly": readOnly,
                        readOnly
                    }}
                    error={validation?.firstName === false} helperText={validation?.firstName === false && "Required Field"}
                    onFocus={() => { if(validation?.firstName === false) setValidation({ ...validation, "firstName": true }); }}
                    onBlur={(e) => setValidation({ ...validation, "firstName": validateText(e.target.value) })}
                    onChange={(e) => updateState("firstName", e.target.value)} />
            </Mui.FormControl>
            <Mui.FormControl variant="outlined" required={!readOnly} className={classes.formControl}>
                <Mui.FormLabel id="lastNameLabel" required={!readOnly}>Last Name</Mui.FormLabel>
                <Mui.TextField className={classes.textField} variant="outlined" aria-required={!readOnly} value={lastName} readOnly={!!user} FormHelperTextProps={{ id: "lastNameHelperText" }}
                    inputProps={{
                        "aria-describedby": "lastNameHelperText " + (readOnlyDescriptorId),
                        "aria-invalid": validation?.lastName === false,
                        "aria-labelledby": "lastNameLabel",
                        "autoComplete": "family-name",
                        "aria-readonly": readOnly,
                        readOnly
                    }}
                    error={validation?.lastName === false} helperText={validation?.lastName === false && "Required Field"}
                    onFocus={() => { if(validation?.lastName === false) setValidation({ ...validation, "lastName": true }); }}
                    onBlur={(e) => setValidation({ ...validation, "lastName": validateText(e.target.value) })}
                    onChange={(e) => updateState("lastName", e.target.value)} />
            </Mui.FormControl>
            <Mui.FormControl variant="outlined" required={!readOnly} className={classes.formControl}>
                <Mui.FormLabel id="emailLabel" required={!readOnly}>E-mail Address</Mui.FormLabel>
                <Mui.TextField className={classes.textField} variant="outlined" aria-required={!readOnly} value={email} readOnly={!!user} FormHelperTextProps={{ id: "emailHelperText" }}
                    inputProps={{
                        "aria-describedby": "emailHelperText " + (readOnlyDescriptorId),
                        "aria-invalid": validation?.email === false,
                        "aria-labelledby": "emailLabel",
                        "autoComplete": "email",
                        "aria-readonly": readOnly,
                        readOnly
                    }}
                    error={validation?.email === false} helperText={validation?.email === false && (!email ? "Required Field" : `Must be a valid email ${MAX_LENGTH_EMAIL} characters or less.`)}
                    onFocus={() => { if(validation?.email === false) setValidation({ ...validation, "email": true }); }}
                    onBlur={(e) => setValidation({ ...validation, "email": validateEmail(e.target.value) })}
                    onChange={(e) => updateState("email", e.target.value)} />
            </Mui.FormControl>
            {readOnly && <Mui.Typography id={readOnlyDescriptorId} variant="srOnly">read only{" "}</Mui.Typography>}
        </Mui.Box>
    );
}