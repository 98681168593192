import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Request from "../components/Steps/Request";

const mapStateToProps = (state) => {
    const { formInputState } = state?.config ?? {};
    const topics = formInputState?.topics ?? {}
    const subtopics = formInputState?.subtopics ?? {}
    const sortAndLeaveOtherAtEnd = (arr = []) => {
        const OTHER = "Other"
        let sortedArr = [...arr].sort()
        const filtedArr = sortedArr.filter(item => item !== OTHER)
        if(sortedArr.length !== filtedArr.length) {
            filtedArr.push(OTHER)
        }
        return filtedArr
    }
    const getTopics = (audience) => {
        return sortAndLeaveOtherAtEnd(topics?.[audience])
    }

    const getSubtopics = (audience, topic) => {
        return sortAndLeaveOtherAtEnd(subtopics?.[audience]?.[topic])
    }
    
    return {
        attachmentTypeMap: formInputState?.attachments ?? {}, 
        configMap: formInputState?.config ?? {},
        getTopics,
        getSubtopics
    };
};


export default withRouter(connect(mapStateToProps, undefined)(Request));
