import { Mui } from "@osu/react-ui";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const usePrefilledSearchParams = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(history?.location?.search) ?? {};
  const audience = searchParams.get("audience");
  const topic = searchParams.get("topic");
  const subtopic = searchParams.get("subtopic");

  const { audienceExists, topicConfig, subtopicConfig, validAudiences = [], details = {} } = useSelector(
    (state) => {
      const audienceConfig =
        state?.config?.formInputState?.config[audience] ?? {};
      const id = audienceConfig?.[topic]?.[subtopic];
      const getRelatedItem = ({ id, topic }) => {
        const data = state?.config?.data || [];
        const foundItem = data.find((item) =>
          id ? item.id === id : topic ? item.topic === topic : false
        );
        return foundItem || {};
      };

      const detailsById = id && getRelatedItem({ id });
      const detailsByTopic = topic && getRelatedItem({ topic });
      const topicConfig = audienceConfig?.[topic];
      const subtopicConfig = topicConfig?.[subtopic];

      return {
        audienceExists: !!audienceConfig,
        validAudiences: state?.audiences?.audiences?.audiences ?? [],
        topicConfig,
        subtopicConfig,
        details: detailsById || detailsByTopic,
      };
    }
  );

  const { defaultState, errors } = useMemo(() => {
    let defaultState = {};
    let errs = [];
    if (validAudiences?.length > 0) {
      if (audience) {
        if(!audienceExists) {
          errs.push(<span>I am a: {audience} <Mui.Typography variant="caption">[Invalid option]</Mui.Typography></span>);
        } else if (validAudiences.includes(audience)) {
          defaultState.audience = audience;
        } else {
          errs.push(
            <span>
              I am a: {audience}{" "}
              <Mui.Typography variant="caption">
                [Requires authentication and staff/faculty affiliation]
              </Mui.Typography>
            </span>
          );
        }

        if (topic) {
          if (topicConfig) {
            defaultState.topic = topic;
            if(subtopic) {
              if (subtopicConfig) {
                defaultState.subtopic = subtopic;
              } else {
                errs.push(
                  <span>
                    {topic} Subtopic: {subtopic}{" "}
                    <Mui.Typography variant="caption">
                      [Invalid option]
                    </Mui.Typography>
                  </span>
                );
              }
            }
          } else {
            errs.push(
              <span>
                Looking for assistance with: {topic}{" "}
                <Mui.Typography variant="caption">
                  [Invalid option]
                </Mui.Typography>
              </span>
            );
          }
        }
      }
    }
    if (details?.topic && details.topic === topic) {
      if (details.id) defaultState.selectionMappingId = details.id;
      if (details.salesforceRoutingId)
        defaultState.salesforceRoutingId = details.salesforceRoutingId;
      if (details.unit) defaultState.unit = details.unit;
    }

    return {
      defaultState,
      errors: errs,
    };
  }, [
    audience,
    audienceExists,
    details?.id,
    details?.salesforceRoutingId,
    details?.topic,
    details?.unit,
    subtopic,
    subtopicConfig,
    topic,
    topicConfig,
    validAudiences,
  ]);

  return {
    searchParamsProvided: audience || topic || subtopic,
    initialState: defaultState,
    errors,
  };
};

export default usePrefilledSearchParams;
