import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import App from '../components';
import { getConfig } from "../../actions-index";

const mapStateToProps = (state) => {
    const { authentication } = state
    const { status = '' } = authentication

    return {
        authStatus: status,
        configStatus: (state?.config?.status ?? "")
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getConfig: () => dispatch(getConfig())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));