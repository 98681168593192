import React, { Fragment/* , useContext, useEffect */ } from 'react'
import PropTypes from 'prop-types'
import { Helmet/*, Mui , PageHeadingContext */ } from "@osu/react-ui";

/* const useStyles = Mui.makeStyles((theme) => {
    return {
        title: {
            fontWeight: 400,
            marginBottom: "1rem"
        }
      }
}); */

export function RenderableView({ pathInformation = {}, renderProps = {} }) {
    const { props = {}, Component, noIndex/* , title: pathTitle, titleProps */ } = pathInformation
    //const { setHeading, setOptions } = useContext(PageHeadingContext)
    //const title = typeof pathTitle === 'function' ? pathTitle(renderProps) : pathTitle
    const authRequired = pathInformation.authRequired
    //const classes = useStyles();
    
    /* useEffect(() => {    
        setHeading(title)
    }, [setHeading, title]) */

    /* useEffect(() => {    
        const options = Object.assign({ component: "h1", variant: "h1", className: classes.title }, titleProps);
        setOptions(options)
    }, [classes, setOptions, titleProps]) */

    return <Fragment>
        {(noIndex || authRequired) && <Helmet>
            <meta id="meta-hidden" name="robots" content="noindex" />
        </Helmet>}
        <Component 
            {...renderProps}  
            {...props} 
        />
    </Fragment>
}

RenderableView.defaultProps = {
    renderProps: {},
    pathInformation: {
        props: {},
        noIndex: false, 
        authRequired: false,
    }
}

RenderableView.propTypes = {
    pathInformation: PropTypes.shape({
        path: PropTypes.string.isRequired, 
        props: PropTypes.object, 
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.func])/* .isRequired */, 
        titleProps: PropTypes.object, 
        Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]).isRequired, 
        noIndex: PropTypes.bool, 
        authRequired: PropTypes.bool
    }).isRequired, 
    renderProps: PropTypes.object, 
    createTitle: PropTypes.func,
}

export default RenderableView