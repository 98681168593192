export const ACTION_STATUS = {
    ERROR: "error",
    LOADING: "loading",
    SUCCESS: "success"
};
export const AUTH_MODE = {
    AMAZON_COGNITO_USER_POOLS: "AMAZON_COGNITO_USER_POOLS",
    AWS_IAM: "AWS_IAM"
};
// MIME types to EDM file type
export const FILE_TYPES = {
    // Microsoft Word (.doc, .docx)
    "application/msword": "MS Word Document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "MS Word Document",
    // Microsoft Excel (.xls, .xlsx)
    "application/vnd.ms-excel": "MS Excel Spreadsheet (Office Open XML Format)",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "MS Excel Spreadsheet (Office Open XML Format)",
    // Graphics and Images
    "image/gif": "Image File Format",
    "image/png": "Image File Format",
    "image/jpeg": "Image File Format",
    "image/bmp": "Image File Format",
    "image/tiff": "Image File Format",
    // Text
    "text/plain": "Text File",
    // PDF
    "application/pdf": "PDF"
};
export const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true";
export const OSU_EMAIL_EXTENSIONS = ["buckeyemail.osu.edu", "osu.edu"];
export const OSU_NAV_ID = 'osu-navbar';
export const MAIN_ID = 'page-content';
export const ACCESSIBLE_REFLOW_HEIGHT = `399px`;
export const TOC_WIDTH = `15rem`;
export const sanitizedHtmlPreferences = {
    allowedTags: [
        "address",
        "article",
        "section",
        "blockquote",
        "dd",
        "div",
        "dl",
        "dt",
        "figcaption",
        "figure",
        "hr",
        "li",
        "ol",
        "p",
        "pre",
        "ul",
        "br",
        "em",
        "i",
        "kbd",
        "a",
        "span",
        "strong",
        "sub",
        "sup",
        "caption",
      ],
      allowedAttributes: {
        a: ["href", "name", "class", "target"],
      },
      transformTags: {
        a: function (tagName, attribs) {
          return {
            tagName,
            attribs: {
                ...attribs,
                class:
                  "MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary",
            }
          };
        },
    }
}