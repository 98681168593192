import { keys } from "lodash";
import { stepKeys, steps } from "./steps";

export function transformRequest(stepState) {
    const input = {};
    keys(stepState).forEach(stepKey => {
        const values = stepState[stepKey].values;
        switch(stepKey) {
            case stepKeys.CONTACT_INFORMATION:
                input.firstName = values.firstName;
                input.lastName = values.lastName;
                input.email = values.email;
                break;
            case stepKeys.REQUEST:
                input.audience = values.audience;
                input.message = values.message;
                input.topic = values.topic;
                input.service = values.service;
                input.subtopic = values.subtopic;
                input.selectionMappingId = values.selectionMappingId;
                input.salesforceRoutingId = values.salesforceRoutingId;
                input.unit = values.unit;
                input.assignmentGroup = values.assignmentGroup;
                input.uServiceProvider = values.uServiceProvider;
                break;
            default:
        }
    });
    return { input };
}

export const getDefaultStepState = (hash = "", stepState) => {
    if(!hash) return -1
    const keyToRender = hash.startsWith("#") ? hash.slice(1) : hash
    const relevantStep = steps.find(({ key }) => (key === keyToRender));

    let stepToRender
    if(stepState[relevantStep?.key]) {
        const stepsPrior = steps.filter(({ index: stepIndex}) => {
            return stepIndex < relevantStep.index
        })
        const reversedSteps = [...stepsPrior].reverse()
        const lastCompletedStep = reversedSteps.find(item => {
            return stepState[item?.key].isComplete
        }) ?? {}

        if(lastCompletedStep?.index !== undefined) {
            stepToRender = (relevantStep.index <= (lastCompletedStep.index + 1))
                ? relevantStep.index
                : lastCompletedStep.index + 1
        } else if (relevantStep.index === 0) {
            if(stepState[relevantStep?.key]?.isComplete) {
                return relevantStep.index
            } else if(stepsPrior?.length === 1 && (stepKeys.LOGIN === stepsPrior[0].key) && !stepState[stepKeys.LOGIN]?.isComplete) {
                return stepsPrior[0].index
            }
            return 0
        }
    }
    if(Number.isNaN(stepToRender) || stepToRender === undefined) stepToRender = -1
    return stepToRender
}
