import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Form from "../components";
import { SUBMIT_REQUEST, submitRequest, getConfig, logout, resetFileUpload, resetState, setFileUploadProgress, uploadFile } from "../../actions-index";

const mapStateToProps = (state) => {
    const { user = null, status: userStatus = "" } = state.authentication;
    const { data: config = [], status: configStatus = "" } = state.config;
    const { status: requestStatus = "" } = state.request;
    const validAudiences = state.audiences?.audiences?.audiences ? state.audiences.audiences.audiences : [];
    return {
        fileUpload: state.fileUpload,
        user,
        userStatus,
        requestStatus,
        config,
        configStatus,
        validAudiences
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitRequest: (stepState) => dispatch(submitRequest(stepState)),
        getConfig: () => dispatch(getConfig()),
        logout: (search, hash) => dispatch(logout(search, hash)),
        resetFileUpload: () => dispatch(resetFileUpload()),
        resetRequest: () => dispatch(resetState(SUBMIT_REQUEST, {})),
        setFileUploadProgress: (progress) => dispatch(setFileUploadProgress(progress)),
        uploadFile: (file, uploadName) => dispatch(uploadFile(file, uploadName)),
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
