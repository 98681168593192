import React, { useContext, useEffect } from "react";
import {
  PageHeadingContext,
  withPageHeading,
  Mui
} from "@osu/react-ui";
import Routes from "../../Routes/components";

const useStyles = Mui.makeStyles((theme) => {
  return {
    root: {
      minHeight: "calc(100vh - 42px - var(--nav-height) - var(--footer-height))",
      marginBottom: "2rem"
    },
    toc: {
      zIndex: theme.zIndex.appBar - 2
    },
  };
});

function Main(props = {}) {
  const { className, unauthorized, loading, spacingTop, ...rest } = props;
  const classes = useStyles();
  const { setHeading, setApplicationName } = useContext(PageHeadingContext);

  useEffect(() => {    
    if(setHeading) {
      setHeading("help.osu.edu")
    }
  }, [setHeading])

  useEffect(() => {    
    if(setApplicationName) {
      setApplicationName("help.osu.edu")
    }
  }, [setApplicationName])

  return (
      <Mui.Box flexGrow={100} className={`${className} ${classes.root}`.trim()} {...rest}>
        <Mui.Container className="margin-y-4" maxWidth="lg">
          <Routes />
        </Mui.Container>
      </Mui.Box>
  );
}

Main.defaultProps = {
  className: "",
  tabIndex: "-1",
  component: "main",
};

export default withPageHeading(Main);