import { useMediaQuery } from "@material-ui/core";
import { Mui } from "@osu/react-ui";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../App/context";
import { numOfStepsToComplete, steps as originalSteps } from "../steps";
import useStyles from "../styles";
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';

const convertIndexToStep = (idx) => idx + 1;
const focusOnRef = (ref, focusableId) => {
    if(ref?.current?.id && (ref?.current?.id === focusableId)) {
      const hasFocus = (ref?.current === document.activeElement)
      if(!hasFocus) {
          ref.current.focus()
      }
    }
}

function DesktopStepper(props = {}) {
  const theme = Mui.useTheme();
  const { elevation, activeStep, checkStateByKey, createLabelId, focusableId, setActiveStep, isRequestComplete, isRequestResolving, isReviewStep } = props;
  const ref = useRef();
  const { darkMode } = useContext(AppContext);
  const xsScreen = useMediaQuery(theme?.breakpoints.down(470));
  const classes = useStyles({ elevation });
  const disableHeaderLinks = isRequestComplete || isRequestResolving

  let steps = originalSteps.map((originalStep, index) => {
    const active = originalStep?.index === activeStep;
    const tabIndex = active ? 0 : -1;
    const { isComplete: isStepComplete } = checkStateByKey
      ? checkStateByKey(originalStep?.key)
      : {};
    let srOnly = active ? "Current: " : isStepComplete ? "Completed: " : "";
    const labelId = createLabelId(originalStep?.index);
    return {
      ...originalStep,
      active,
      tabIndex,
      isStepComplete,
      srOnly,
      labelId,
      triggerFocus: (focusableId === originalStep?.index) && active
    };
  });

  useEffect(() => {
    focusOnRef(ref, focusableId)
  }, [focusableId])

  return (
    <Mui.Stepper
      elevation={darkMode ? elevation : 0}
      activeStep={(isRequestComplete && isReviewStep) ?  (activeStep + 1) : activeStep}
      orientation={xsScreen ? "vertical" : "horizontal"}
      alternativeLabel={xsScreen ? false : true}
      component="ol"
      className="margin-0"
    >
      {steps.map(({ isStepComplete, srOnly, index, key, label, active, labelId }) => {
        if (index >= 0) {
          let stepProps = {
            role: "listitem"
          };
          let focusableTextProps = {
            id: labelId,
            className: "",
            classes: {}
          }

          let StepperLabel = Mui.Button
          if(disableHeaderLinks) {
            StepperLabel = Mui.Typography
            focusableTextProps.variant = "body2"
          } else {
            focusableTextProps.className = "text-transform-none"
            focusableTextProps.size = "small"
            if(active) {
              focusableTextProps["aria-disabled"] = true
              focusableTextProps.ref = ref
              focusableTextProps.tabIndex = "-1"
              focusableTextProps.className += ` Mui-disabled ${classes.disabledButton} ${classes.fontWeightNormal}`
              focusableTextProps.classes.label = classes.buttonLabelWhite
            } else if((isStepComplete)) {
              focusableTextProps.role = "link"
              focusableTextProps.onClick = () => {
                setActiveStep(index)
              }
            } else {
              focusableTextProps.disabled = true
              focusableTextProps.className += ` ${classes.disabledButton} ${classes.fontWeightNormal}`
              focusableTextProps.classes.label = classes.buttonLabelDisabled
            }
          }

          return (
            <Mui.Step key={key} {...stepProps}>
              <Mui.StepLabel StepIconProps={{ classes: {
                root: (!active && !isStepComplete) && classes.outlineCircle,
                text: (!active && !isStepComplete) && classes.outlineCircleText,
                completed: classes.completed
              }}} icon={convertIndexToStep(index)}>
                <StepperLabel  {...focusableTextProps}>
                    {srOnly && (
                    <Mui.Typography variant="srOnly">{srOnly}</Mui.Typography>
                    )}
                    {label}
                </StepperLabel>
              </Mui.StepLabel>
            </Mui.Step>
          );
        }
        return null;
      })}
    </Mui.Stepper>
  );
}

function MobileStepper(props) {
  const { activeStep, focusableId, createLabelId, isRequestComplete, isReviewStep, isRequestResolving, errorSubmitting } = props
  const currentStep = originalSteps.find(({ index }) => index === activeStep)
  const nextStep = originalSteps.find(({ index }) => index === (activeStep + 1))
  const classes = useStyles()
  const valueOfProgress = ((activeStep + 1)/numOfStepsToComplete) * 100
  const ref = useRef()
  const disableHeaderLinks = isRequestComplete || isRequestResolving

  useEffect(() => {
    focusOnRef(ref, focusableId)
  }, [focusableId, disableHeaderLinks])

  let secondaryText = "", 
    circularProgress = {
      value: valueOfProgress
    }
  if(nextStep?.label) {
    secondaryText = <Fragment>Next: <Mui.Typography variant="body2"  component="span" color="textPrimary">{nextStep?.label}</Mui.Typography></Fragment>
  } else if(errorSubmitting) {
    secondaryText = "Error"
  } else if (isReviewStep && !isRequestComplete && !isRequestResolving) {
    secondaryText = "Submit to complete"
  } else if (isRequestComplete) {
    secondaryText = "Completed"
    circularProgress.value = 100
    circularProgress.color = "success"
  }

  return <Mui.Box className={classes.mobileStepper} display="flex" justifyContent="space-between">
    <Mui.Box alignItems="flex-end">
      <Mui.Typography id={createLabelId(currentStep?.index)} component="h3" variant="h6" tabIndex="-1" ref={ref}>
          {currentStep?.label}
        </Mui.Typography>
        {secondaryText && <Mui.Typography variant="body2" color="secondary">{secondaryText}</Mui.Typography>}
    </Mui.Box>
    {
      (isReviewStep && (isRequestComplete || errorSubmitting)) 
        ? errorSubmitting ? <ErrorOutline className={classes.errorCircle} /> : <CheckCircleOutline className={classes.checkCircle}  />
        : <Mui.Box position="relative" display="inline-flex" className={classes.stepperProgress} >
          <Mui.CircularProgress size="4rem" variant="determinate" {...circularProgress} />
          <Mui.Box className={classes.progress}
          >
            <Mui.Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{activeStep + 1} of {numOfStepsToComplete}</Mui.Typography>
          </Mui.Box>
      </Mui.Box>
    }
  </Mui.Box>
}

function Header (props) {
  return <Fragment>
    <Mui.Hidden mdUp>
      <MobileStepper {...props} />
    </Mui.Hidden>
    <Mui.Hidden smDown>
      <DesktopStepper {...props} />
    </Mui.Hidden>
  </Fragment>
}

export default Header;
