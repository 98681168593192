import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { FileUploader, Mui } from "@osu/react-ui";
import { compact, find, keys, values as _values } from "lodash";
import { getUpdatedStepStateValues } from "../../steps";
import LightbulbIcon from "../../../Common/components/LightbulbIcon";
import { ACTION_STATUS, FILE_TYPES, sanitizedHtmlPreferences } from "../../../util/constants";
import useStyles from "../../styles";
import sanitizedHtml from "sanitize-html"

const FILE_MAX_SIZE = 20971520; // 20MB
const FILE_UPLOADER_ID = "file-uploader";
const FILE_UPLOADER_INPUT_ID = `${FILE_UPLOADER_ID}-btn-upload`;
const MAX_LENGTH_MESSAGE = 1500;

export default function Request(props = {}) {
    const {
        fileUpload = {}, resetFileUpload, setErrorProps, setFileUploadProgress, stepKey, stepState,
        config, configStatus, updateStepState, uploadFile, elevation, focusableId, validAudiences,
        attachmentTypeMap, configMap, getTopics, getSubtopics
    } = props;
    const fileContentTypes = keys(FILE_TYPES);

    const { file: fileUploadFile, progress: fileUploadProgress = 0, status: fileUploadStatus = "" } = fileUpload;
    const { values } = stepState;
    const { file, message = "", selectionMappingId } = values;
    const [validation, setValidation] = useState({});
    const [fileErrorText, setFileErrorText] = useState("");
    const classes = useStyles({ elevation });
    const selectRef = useRef()

    const validateValue = (value) => {
        return !!value;
    };
    const validateMessage = (message) => {
        return (validateValue(message) && message.length <= MAX_LENGTH_MESSAGE);
    };

    const updateState = (fieldName, fieldValue) => {
        const updatedValues = getUpdatedStepStateValues(stepState, fieldName, fieldValue, null);
        updateStepState(stepKey, { ...stepState, values: updatedValues });
    };
    const updateStateCallback = useCallback(updateState, [stepKey, stepState, updateStepState]);

    const resetFile = () => {
        resetFileUpload();
        const fileUploadInput = document.getElementById(FILE_UPLOADER_INPUT_ID);
        if(fileUploadInput) fileUploadInput.value = []; // reset file input
    }
    const resetFileCallback = useCallback(resetFile, [resetFileUpload]);

    const updateTopicStateOnChangeOfAudience = (values) => {
        let newConfig = config.find(configItem => {
            return (configItem.topic === values.topic && configItem.subtopic === values.subtopic && configItem.constituentAudience === values.audience);
        });
        let matches
        if(!newConfig) {
            matches = config.filter(configItem => {
                return configItem.topic === values.topic && configItem.constituentAudience === values.audience
            })
        }

        if(newConfig) {
            updateStateCallback("topic", newConfig.topic);
            updateStateCallback("subtopic", newConfig.subtopic);
            updateStateCallback("selectionMappingId", newConfig.id);
            updateStateCallback("salesforceRoutingId", newConfig.salesforceRoutingId);
            updateStateCallback("service", newConfig.service);
            updateStateCallback("unit", newConfig.unit);
            updateStateCallback("assignmentGroup", newConfig.assignmentGroup);
            updateStateCallback("uServiceProvider", newConfig.uServiceProvider);
        } else {
            updateStateCallback("topic", matches?.length ? values?.topic : null);
            updateStateCallback("subtopic", null);
            updateStateCallback("selectionMappingId", null);
            updateStateCallback("salesforceRoutingId", null);
            updateStateCallback("service", null);
            updateStateCallback("unit", null);
            updateStateCallback("assignmentGroup", null);
            updateStateCallback("uServiceProvider", null);
        }
    };
    const updateTopicStateOnChangeOfAudienceCallback = useCallback(updateTopicStateOnChangeOfAudience, [config, updateStateCallback]);
    const onAudienceChange = (audience) => {
        setValidation({ ...validation, "audience": validateValue(audience), "topic": true, "subtopic": true });
        updateStateCallback("audience", (audience === "" ? null : audience));
        updateTopicStateOnChangeOfAudienceCallback(values);
    };

    const onTopicChange = (audience, topic) => {
        setValidation({ ...validation, "topic": validateValue(topic), "subtopic": true });
        updateStateCallback("topic", (topic === "" ? null : topic));
        updateStateCallback("subtopic", null);
        const topicValue = configMap[audience][topic];
        if(topic && typeof topicValue === "string") { // id (no subtopic)
            const selectionMappingId = (topic === "" ? null : topicValue);
            updateStateCallback("selectionMappingId", selectionMappingId);
            const configItem = find(config, ["id", selectionMappingId]);
            if(selectionMappingId && configItem) {
                updateStateCallback("salesforceRoutingId", configItem.salesforceRoutingId);
                updateStateCallback("service", configItem.service);
                updateStateCallback("unit", configItem.unit);
                updateStateCallback("assignmentGroup", configItem.assignmentGroup);
                updateStateCallback("uServiceProvider", configItem.uServiceProvider);
            } else {
                updateStateCallback("salesforceRoutingId", null);
                updateStateCallback("service", null);
                updateStateCallback("unit", null);
                updateStateCallback("assignmentGroup", null);
                updateStateCallback("uServiceProvider", null);
            }
        } else {
            updateStateCallback("selectionMappingId", null);
            updateStateCallback("salesforceRoutingId", null);
            updateStateCallback("service", null);
            updateStateCallback("unit", null);
            updateStateCallback("assignmentGroup", null);
            updateStateCallback("uServiceProvider", null);
        }
    };

    const onSubtopicChange = (audience, topic, subtopic) => {
        setValidation({ ...validation, "subtopic": validateValue(subtopic) });
        updateStateCallback("subtopic", (subtopic === "" ? null : subtopic));
        const selectionMappingId = (subtopic === "" ? null : configMap[audience][topic]?.[subtopic]);
        updateStateCallback("selectionMappingId", selectionMappingId);
        const configItem = find(config, ["id", selectionMappingId]);
        if(selectionMappingId && configItem) {
            updateStateCallback("salesforceRoutingId", configItem.salesforceRoutingId);
            updateStateCallback("service", configItem.service);
            updateStateCallback("unit", configItem.unit);
            updateStateCallback("assignmentGroup", configItem.assignmentGroup);
            updateStateCallback("uServiceProvider", configItem.uServiceProvider);
        } else {
            updateStateCallback("salesforceRoutingId", null);
            updateStateCallback("service", null);
            updateStateCallback("unit", null);
            updateStateCallback("assignmentGroup", null);
            updateStateCallback("uServiceProvider", null);
        }
    };
    
    useEffect(() => {
        // Note: Subtopic displays as required on the UI, but really only selectionMappingId is required.  Selecting subtopic is one way to set selectionMappingId.
        const requiredFields = [stepState?.values?.audience, stepState?.values?.topic, stepState?.values?.selectionMappingId, stepState?.values?.message];
        const validationFlags = _values(validation);
        const isFormComplete = (
            compact(requiredFields).length === requiredFields.length && // has all required fields
            validationFlags.every(validationFlag => (validationFlag === true)) // all validation flags are true
        );
        if(stepState.isComplete !== isFormComplete) updateStepState(stepKey, { ...stepState, isComplete: isFormComplete });
    }, [stepKey, stepState, updateStepState, validation]);

    // when the selectionMappingId changes, clear the file upload if the new selectionMappingId does not support attachments
    useEffect(() => {
        const attachmentTypeMapKeys = keys(attachmentTypeMap);
        const attachmentType = (attachmentTypeMap[selectionMappingId] ?? null);
        if(file?.name && attachmentTypeMapKeys.length > 0 && !attachmentType) resetFileCallback();
    }, [attachmentTypeMap, file?.name, resetFileCallback, selectionMappingId])

    // when the file changes, update state
    useEffect(() => {
        if(fileUploadFile?.name && file?.name !== fileUploadFile?.name) updateStateCallback("file", fileUploadFile);
        if(!fileUploadFile?.name && file !== null) updateStateCallback("file", null);
    }, [file, fileUploadFile, updateStateCallback]);

    // when the file upload fails, set error text and clear file state
    useEffect(() => {
        if(fileUploadStatus === ACTION_STATUS.ERROR) {
            setFileErrorText("The file failed to upload.");
            resetFileCallback();
        }
    }, [fileUploadStatus, resetFileCallback]);

    // on unmount, clear the error alert
    useEffect(() => {
        return () => {
            setErrorProps(null);
        };
    }, [setErrorProps]);

    const audiences = validAudiences;
    useEffect(() => {
        if(selectRef?.current && audiences?.length) {
            const parentHasFocus = document.activeElement.id === focusableId
            if(parentHasFocus) {
                selectRef.current.focus()
            }
        }
    }, [audiences, audiences?.length, focusableId])

    const topics = getTopics(values?.audience)
    const subtopics = getSubtopics(values?.audience, values?.topic) ?? []
    const subtopicOptions = subtopics.map(subtopic => <option key={subtopic} value={subtopic}>{subtopic}</option>);

    const configItem = (selectionMappingId ? find(config, ["id", selectionMappingId]) : null);
    const attachmentType = (configItem ? (attachmentTypeMap[selectionMappingId] ?? null) : null);
    const helpText = ((configItem && configItem.helpText) ? configItem.helpText : null);

    const onFileUpload = (data) => {
        const file = data[0];
        if(file.size > FILE_MAX_SIZE) {
            setFileErrorText("The file is too large to upload (max size: 20MB).");
            resetFile();
        } else if(!fileContentTypes.includes(file.type)) { // need to validate the file type because the file dialog has an option that opens it up to all file types
            setFileErrorText(`Invalid file type${(file.type ? ` "${file.type}"` : "")}.`);
            resetFile();
        } else {
            setFileErrorText("");
            uploadFile(file);
        }
    };
    
    const sanitizedHelpText = sanitizedHtml(helpText, sanitizedHtmlPreferences);

    const showLoader = ![ACTION_STATUS.SUCCESS].includes(configStatus) && audiences?.length === 0
    let audiencesToRender = showLoader ? ["Loading options..."] : audiences
    if((ACTION_STATUS.ERROR === configStatus) && audiences?.length === 0) {
        audiencesToRender = ["Could not load options"]
    }
    
    return (
        <div>
            <Mui.FormControl  variant="outlined" required={true} className={classes.formControl}>
                <Mui.InputLabel className={classes.selectLabel} htmlFor="audienceSelect"> I am a...</Mui.InputLabel>
                <Mui.Select  classes={classes.selectBody} native required={true} label=" I am a..." value={values.audience ?? ""}
                    error={validation?.audience === false}
                    inputProps={{ id: "audienceSelect", "aria-describedby": "audienceHelperText", "aria-invalid": validation?.audience === false, "aria-required" : true, ref: selectRef }}  
                    onChange={(e) => onAudienceChange(e.target.value)}>
                    <option value=""></option>
                    {audiencesToRender.map(audience => {
                        return (<option key={audience} value={audience}>{audience}</option>);
                    })}
                </Mui.Select>
                {validation?.audience === false && <Mui.FormHelperText id="audienceHelperText" error={true}>Required Field</Mui.FormHelperText>}
            </Mui.FormControl>
            {values?.audience &&
                <Fragment>
                    <Mui.FormControl variant="outlined" required={true} className={classes.formControl}>
                        <Mui.InputLabel className={classes.selectLabel} htmlFor="topicSelect">Looking for help with...</Mui.InputLabel>
                        <Mui.Select className={classes.selectBody} native required={true} label="Looking for help with..." value={values.topic ?? ""}
                        error={validation?.topic === false}
                        inputProps={{ id: "topicSelect", "aria-describedby": "topicHelperText", "aria-invalid": validation?.topic === false, "aria-required" : true }}
                            onChange={(e) => onTopicChange(values.audience, e.target.value)}>
                            <option value="">{(values.audience ? "" : "Loading options...")}</option>
                            {topics.map(topic => {
                                return (<option key={topic} value={topic}>{topic}</option>);
                            })}
                        </Mui.Select>
                        {validation?.topic === false && <Mui.FormHelperText id="topicHelperText" error={true}>Required Field</Mui.FormHelperText>}
                    </Mui.FormControl>
                    {(values?.topic && subtopicOptions.length > 0) &&
                        <Mui.FormControl variant="outlined" required={true} className={classes.formControl}>
                            <Mui.InputLabel className={classes.selectLabel} htmlFor="subtopicSelect">{values.topic ? `${values.topic} ` : ""}Subtopic</Mui.InputLabel>
                            <Mui.Select className={classes.selectBody} native required={true} label={values.topic ? `${values.topic} Subtopic` : "Subtopic"}
                                value={values.subtopic ?? ""} error={validation?.subtopic === false}
                                inputProps={{ id: "subtopicSelect", "aria-describedby": "subtopicHelperText", "aria-invalid": validation?.subtopic === false, "aria-required" : true }} 
                                onChange={(e) => onSubtopicChange(values.audience, values.topic, e.target.value)}>
                                <option value="">{(values.topic ? "" : "Loading options...")}</option>
                                {subtopicOptions}
                            </Mui.Select>
                            {validation?.subtopic === false && <Mui.FormHelperText id="subtopicHelperText" error={true}>Required Field</Mui.FormHelperText>}
                        </Mui.FormControl>
                    }
                </Fragment>
            }
            {((values?.audience && values?.topic && (subtopicOptions.length > 0 ? values?.subtopic : true)) || values?.message) &&
                <Fragment>
                    {helpText && 
                        <Mui.Box display="flex" justifyContent="flex-start" padding={2} className={classes.help}>
                            <Mui.Box className={classes.helpIcon}>
                                <LightbulbIcon color="primary" />
                            </Mui.Box>
                            <Mui.Box>
                                <Mui.Typography component="h3" variant="subtitle2" color="secondary">Common Topics for {values.subtopic ? values.subtopic : values.topic}</Mui.Typography>
                                <Mui.Typography dangerouslySetInnerHTML={{ __html: sanitizedHelpText }} />                                
                            </Mui.Box>
                        </Mui.Box>
                    }
                    <Mui.Box marginTop="0.5rem">
                        <Mui.FormControl variant="outlined" required={true} className={classes.formControl}>
                            <Mui.FormLabel id="messageLabel" required={true}>How can we help you?</Mui.FormLabel>
                            <Mui.TextField variant="outlined" aria-labelledby="messageLabel" aria-describedby="messageHelperText" aria-required="true" value={message}
                                aria-invalid={validation?.message === false} error={validation?.message === false}
                                helperText={`${validation?.message === false ? (!message ? "Required Field: " : "Max Characters Exceeded: ") : ""} Characters ${message?.length ?? 0} of ${MAX_LENGTH_MESSAGE}`}
                                FormHelperTextProps={{ "id": "messageHelperText" }}
                                InputProps={{
                                    inputComponent: Mui.TextareaAutosize, 
                                    inputProps: {
                                        "aria-describedby": "messageHelperText",
                                        minRows: 6,
                                        style: { resize: "vertical", width: "100%" }
                                    }
                                }}
                                onFocus={() => { if(validation?.message === false) setValidation({ ...validation, "message": true }); }}
                                onBlur={(e) => setValidation({ ...validation, "message": validateMessage(e.target.value) })}
                                onChange={(e) => {
                                    const message = e.target.value;
                                    setValidation({ ...validation, "message": validateMessage(message) });
                                    updateState("message", message);
                                }} />
                        </Mui.FormControl>
                    </Mui.Box>
                    {attachmentType &&
                        <Fragment>
                            <Mui.FormLabel htmlFor={FILE_UPLOADER_INPUT_ID}>
                                <Mui.Typography variant="body2" color="textPrimary">Supporting Attachment (Optional)</Mui.Typography>
                            </Mui.FormLabel>
                            <FileUploader id={FILE_UPLOADER_ID} accept={fileContentTypes.join(", ")} multiple={false} className={classes.fileUploader}
                                buttonText="ATTACH FILE" errorText={fileErrorText} defaultValue={file?.name && [file]} preventOutOfBounds={true}
                                onFileUpload={onFileUpload} progress={fileUploadProgress} setProgress={setFileUploadProgress}
                                instructionsText="Click or drag to attach file (one file per submission)" onDelete={resetFile}
                             />
                        </Fragment>
                    }
                </Fragment>
            }
        </div>
    );
}

Request.defaultProps = {
    attachmentTypeMap: {},
    configMap: {}
}