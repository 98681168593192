import { useEffect, useState } from "react";

export default function useOnPageLoad () {
    const [isPageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
            if(isPageLoaded !== true) setPageLoaded(true);
        }

        if(document.readyState === "complete") { // check if the page is already loaded
            onPageLoad()
        } else {
            window.addEventListener("load", onPageLoad); // event listener for page load
            return () => { // on unmount, remove event listener
                window.removeEventListener("load", onPageLoad);
            }
        }
    });

    return {
        isPageLoaded
    };
}