import { useState, useEffect, useCallback } from "react";
import { Auth, Hub } from "aws-amplify";
import { useDispatch } from "react-redux";
import { receiveAmplifyHubUser } from "../../actions-index";
import { redirectToLogIn } from "../../util/login";
import { useHistory, useLocation } from "react-router-dom";
import { isUnauthenticated } from "../../util/functions";
import { authenticationFlowPaths } from "../../Routes/paths";

const CHANNELS = {
  auth: "auth",
  core: "core",
};

const EVENTS = {
  customOAuthState: "customOAuthState",
  signIn: "signIn",
  signOut: "signOut",
};

export default function useAmplifyHub() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [triggerHubAuthUseEffect, setTriggerHubAuthUseEffect] = useState(false);
  const location = useLocation();
  const pushRedirectTo = history.push

  const navigateToRedirectPath = useCallback((path) => {
    if (path) {
      const redirectExclusions = authenticationFlowPaths.map((paths) => ({ path: paths?.path }));
      let redirectPath = '/'
      
      const splitPaths = path.split('?')
      if(!!path && path.startsWith('/') && splitPaths[0] &&  !redirectExclusions.includes(splitPaths[0])) {
        redirectPath = path
      }
      
      if(location?.pathname !== redirectPath) {
        pushRedirectTo(redirectPath);
      }
    }
  }, [location?.pathname, pushRedirectTo])

  const manageHubSignIn = useCallback((payload) => {
    const user = payload?.data?.signInUserSession?.idToken?.payload
    setTriggerHubAuthUseEffect(true);
    if(user) {
      dispatch(receiveAmplifyHubUser(user));
    } else {
      dispatch(receiveAmplifyHubUser({ status: 'error' }));
    }
  }, [dispatch])

  const manageHubSignOut = useCallback(() => {
    dispatch(receiveAmplifyHubUser({ status: 'cleared' }))
  }, [dispatch])

  useEffect(() => {
    let isMounted = true;

    const HubListener = () => {
      Hub.listen(CHANNELS.auth, (data) => {
        onAuthEvent(data?.payload);
      });
    };

    const onAuthEvent = (payload) => {
      if (!isMounted) {
        return
      }
      switch (payload?.event) {
        case EVENTS.customOAuthState:
          navigateToRedirectPath(payload?.data)
          break;
        case EVENTS.signIn:
          manageHubSignIn(payload)
          break;
        case EVENTS.signOut:
          manageHubSignOut()
          break;
        default:
          return;
      }
    };

    HubListener();

    return () => {
      Hub.remove(CHANNELS.auth);
      isMounted = false;
    };
  }, [triggerHubAuthUseEffect, manageHubSignIn, manageHubSignOut, navigateToRedirectPath]);

  async function handleSignIn(redirectPath, options = {}) {
    const { doNotRedirect = false } = options
    try {
      dispatch(receiveAmplifyHubUser({ status: "loading" }));
      const data = await Auth.currentAuthenticatedUser();
      if (Object.keys(data?.signInUserSession?.idToken?.payload || {}).length > 0) {
        dispatch(receiveAmplifyHubUser(data?.signInUserSession?.idToken?.payload));
      } else {
        throw new Error("Improper user session");
      }
    } catch (error) {
      if (isUnauthenticated(error) && !doNotRedirect) {
        redirectToLogIn(redirectPath);
      } else {
        dispatch(receiveAmplifyHubUser({ status: "error" }));
      }
    }
  }

  const handleSignOut = async () => {
    try {
      setTriggerHubAuthUseEffect(false);
      await Auth.signOut();
      if (location?.pathname !== "/logout") {
        history.push("/logout");
      }
    } catch (error) {
      console.error("Error signing out user ", error);
    }
  };

  return {
    handleSignIn,
    handleSignOut
  };
}