import { CONFIG } from "../actions-index";
import { ACTION_STATUS } from "../util/constants";
import { buildAction, getGraphQLAuthMode } from "../util/functions";
import * as Queries from "../graphql/queries";
import { sendRUMEvent } from "@osu/wm-react-analytics"
import { API } from "aws-amplify";
import { buildFormControlOptions } from "../Authentication/actions"
// generic function to reset state in Redux
export function resetState(type, payload) {
    return (dispatch) => {
        dispatch(buildAction(type, payload));
    };
}

export function getConfig(unit = null) {
    return async (dispatch, getState) => {
        const state = getState()
        const initialId = state?.authentication?.user?.osuid
        const emplid = initialId || "no-user"
        const authMode = getGraphQLAuthMode(state)
        try {
            dispatch(buildAction(CONFIG, { status: ACTION_STATUS.LOADING }));
            const response = await API.graphql({ authMode, query: Queries.combinedFormControlAndConfig, variables: { unit, emplid }});
            if(!response?.data?.getConfig) {
                throw new Error("Unable to get configuration")
            }
            const configData = response?.data?.getConfig?.config
            let configMapState = {};
            let attachmentTypeMapState = {};
            let topics = {}
            let subtopics = {}
            const hasConfigData = Array.isArray(configData) && configData?.length
            if(hasConfigData) {
                configData.forEach(({ attachmentAllowed = null, constituentAudience, id, subtopic, topic }) => {
                    attachmentTypeMapState[id] = attachmentAllowed;
                    if(!configMapState[constituentAudience]) {
                        configMapState[constituentAudience] = {};
                    }
                    if(!topics[constituentAudience]) {
                        topics[constituentAudience] = []
                    }
                    if(!subtopics[constituentAudience]) {
                        subtopics[constituentAudience] = {}
                    }
                    
                    if(topic)  {
                        topics[constituentAudience].push(topic)
                    }
                    
                    if(!configMapState[constituentAudience][topic]) {
                        configMapState[constituentAudience][topic] = {};
                    }
                    if(subtopic === null) {
                        configMapState[constituentAudience][topic] = id;
                    } else {
                        if(!configMapState[constituentAudience][topic][subtopic]) {
                            configMapState[constituentAudience][topic][subtopic] = id;
                        }
                        if(!subtopics[constituentAudience][topic]) {
                            subtopics[constituentAudience][topic] = []
                        }
                        subtopics[constituentAudience][topic].push(subtopic)
                    }
                    topics[constituentAudience] = [...new Set(topics[constituentAudience])]
                    if(subtopics[constituentAudience][topic]) {
                        subtopics[constituentAudience][topic] = [...new Set(subtopics[constituentAudience][topic])]
                    }
                });
            }
            const formInputState = {
                config: configMapState ?? {},
                topics,
                subtopics,
                attachments: attachmentTypeMapState ?? {},
            }
            let payload = {
                data: (configData ?? []),
                formInputState
            }

            dispatch(buildAction(CONFIG, payload));
            const refreshedState = getState()
            const refreshedId = refreshedState?.authentication?.user?.osuid
            if(!refreshedId) {
                dispatch(buildFormControlOptions(emplid, response?.data?.controlFormOptions))
            }
            if(!hasConfigData) {
                throw new Error("No data passed into the config")
            }
            
        } catch(error) {
            console.error("Get Config Error: ", error);
            sendRUMEvent('recordError', error);
            dispatch(buildAction(CONFIG, { status: ACTION_STATUS.ERROR }));
        }
    };
}