import React, { useEffect, useRef } from "react";
import Main from '../../Main/components'
import { MAIN_ID } from "../../util/constants";
import { withOSUTheming, useScrollToHash, Mui, withTableOfContents, useFooterWithTOC, withDarkMode } from '@osu/react-ui'
import { AppProvider } from "../context";
import { Navbar as OSUNavBar } from "@osu/navbar";
import withAuthentication from "../../Authentication/withAuthentication";
import useCssVariableUpdater from "../hooks/useCssVariableUpdater";
import useOnPageLoad from "../hooks/useOnPageLoad";
import Footer from "@osu/footer"

function App(props = {}) {
  const { getConfig, navHeight, darkMode, configStatus } = props
  const { ref } = useScrollToHash()
  const footerRef = useRef()
  useFooterWithTOC({ ref: footerRef })
  const trigger = Mui.useScrollTrigger({
    disableHysteresis: true,
    threshold: navHeight
  });
  const { isPageLoaded } = useOnPageLoad();
  // when the page is loaded (wait for silent challenge token, if necessary), get the config
  useEffect(() => {
    if(isPageLoaded === true && configStatus === "") getConfig();
  }, [getConfig, isPageLoaded, configStatus]);

  return (
    <div className="App" ref={ref}>
      <OSUNavBar darkMode={darkMode} />
      <Main spacingTop={!trigger} className="anchor-link" id={MAIN_ID} />
      <Footer white={!darkMode} darkMode={darkMode} ref={footerRef} />
    </div>
  );
}

const TOCWrappedApp = withTableOfContents(App)

function Provider(props = {}) {
  console.log('Provider props', props)
  const { authorized, handleSignIn, authStatus, handleSignOut, darkMode, getConfig, configStatus } = props;
  const { navHeight } = useCssVariableUpdater()
  const appContext = {
    authStatus,
    authorized,
    handleSignIn,
    handleSignOut,
    navHeight,
    darkMode
  }
  const appProps = { darkMode, getConfig, navHeight, configStatus };
  
  return (
    <AppProvider value={appContext}>
      <TOCWrappedApp {...appProps} />
    </AppProvider>
  );
}

export default withDarkMode(withOSUTheming(withAuthentication(Provider)))