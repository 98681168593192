import { useEffect, useRef } from 'react'
import { OSU_NAV_ID } from '../../util/constants'

const useCssVariableUpdater = () => {
    const navHeight = useRef()
    const navbarRect = document.getElementById(OSU_NAV_ID)?.getBoundingClientRect() || {}
    const headerRect = document.getElementById('app-header')?.getBoundingClientRect() || {}
    const outOfView = navbarRect.y && navbarRect.y <= 0

    useEffect(() => {
        const root = document.documentElement
        let newNavheight = !outOfView ? navbarRect.height + headerRect.height : headerRect.height

        if(!isNaN(newNavheight)) {
            navHeight.current = newNavheight
            root.style.setProperty('--nav-height', newNavheight + 'px')
        }
    }, [navbarRect.height, headerRect.height, outOfView])

    return {
        navHeight: navHeight.current
    }
}

export default useCssVariableUpdater