import React, { Fragment, useCallback, useContext, useEffect, useReducer, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { Mui, PageHeading, PageHeadingContext, usePrevious } from "@osu/react-ui";
import { Auth } from "aws-amplify";
import { isEmpty, pick } from "lodash";
import { AppContext } from "../../App/context";
import ErrorAlert from "../../Common/components/ErrorAlert";
import { ACTION_STATUS } from "../../util/constants";
import { getInitialStepState, stepReducer, steps, stepKeys, getStep, numOfStepsToComplete } from "../steps";
import useStyles from "../styles";
import Header from "./Header";
import usePrefilledSearchParams from "../hooks/usePrefilledSearchParams";
import ErrorList from "./ErrorList";
import { getDefaultStepState } from "../transform";

const elevation = 4
export default function Form(props = {}) {
    const { submitRequest, fileUpload, getConfig, logout, resetFileUpload, resetRequest, setFileUploadProgress, uploadFile, user, userStatus,
        requestStatus, config, configStatus, validAudiences } = props;
    const location = useLocation()
    const { setPageTitle, pageTitle } = useContext(PageHeadingContext);
    const isRequestResolving = ([ACTION_STATUS.LOADING].includes(requestStatus));
    const hasPageError = (configStatus === ACTION_STATUS.ERROR);
    const isRequestComplete = (requestStatus === ACTION_STATUS.SUCCESS);
    const hasRequestError = (requestStatus === ACTION_STATUS.ERROR)
    const isFileUploading = (fileUpload?.status === ACTION_STATUS.LOADING);
    const { searchParamsProvided, initialState: searchParamState, errors: searchParamErrors } = usePrefilledSearchParams()
    const { handleSignIn } = useContext(AppContext)
    const history = useHistory();
    const [useSearchParams, setUseSearchParams] = useState(true);
    const [errorProps, setErrorProps] = useState(null);
    const [stepState, dispatchStepState] = useReducer(stepReducer, getInitialStepState());    
    const [activeStep, setActiveStepState] = useState(getDefaultStepState(location?.hash, stepState));
    const [isIdleTimerStarted, setIdleTimerStarted] = useState(false);
    const [startIdleTimer, setStartIdleTimer] = useState(false);
    const [isSessionExpired, setSessionExpired] = useState(false);
    const [focusableId, setFocusableId] = useState("")
    const classes = useStyles({ elevation });
    const errorWasShown = useRef()
    const previousUser = usePrevious(user)
    const pushHistory = history.push
    const historyListen = history.listen

    const updateHistoryWithStep = useCallback((stepId) => {
        const { key: newStepKey } = getStep(stepId) ?? {}
        const searchParams = new URLSearchParams(history?.location?.search) ?? {};
        const audience = searchParams.get("audience");
        const topic = searchParams.get("topic");
        const subtopic = searchParams.get("subtopic");
        let cleanedSearch = new URLSearchParams({})
        if(audience) cleanedSearch.set("audience", audience)
        if(topic) cleanedSearch.set("topic", topic)
        if(subtopic) cleanedSearch.set("subtopic", subtopic)
        
        newStepKey && pushHistory({
            hash: `#${newStepKey}`,
            search: cleanedSearch.toString() || ""
        })
    }, [history?.location?.search, pushHistory])

    const setActiveStep = useCallback((stateParms) => {
        if(typeof stateParms === 'function') {
            return setActiveStepState((existing) => {
                const updatedStep = stateParms(existing)
                updateHistoryWithStep(updatedStep)
                return updatedStep
            })
        } 
        updateHistoryWithStep(stateParms)
        setActiveStepState(stateParms)
    }, [updateHistoryWithStep])

    useEffect(() => {
        return historyListen((loc, action) => {
            const lockState = isRequestResolving || isRequestComplete || hasRequestError
            if(action === "POP" &&  !lockState) {
                setActiveStep(getDefaultStepState(loc.hash, stepState))
            }
        })
    }, [historyListen, isRequestResolving, isRequestComplete, hasRequestError, setActiveStep, stepState])
        
    const { Component, index: stepIndex, key: stepKey } = steps.find(({ index }) => {
        return (index === activeStep)
    }) ?? {};
    const isReviewStep = (stepKey === stepKeys.REVIEW);
    const stepRoRenderAsActive = (stepIndex === -1) ? 0 : activeStep
    const currentStepDetails = getStep(stepRoRenderAsActive)
    const login = async (osuLogin = false) => {
        if(osuLogin === true) {
            if(user) {
                setActiveStep(0);
            } else {
                handleSignIn();
            }
        } else {
            let authUser;
            try {
                authUser = await Auth.currentAuthenticatedUser();
            } catch(error) {
                console.log(error); // the user is not authenticated
            }
            if(authUser) {
                logout(location.search, `#${stepKeys.CONTACT_INFORMATION}`); // logout and bring the user back to the Contact Information page
            } else {
                setActiveStep(0);
            }
        }
    };
    const onIdle = () => {
        if(isIdleTimerStarted === true) {
            setSessionExpired(true); // open the session dialog
            setTimeout(() => setSessionExpired(false), 300000); // close the session dialog automatically when the timout elapses
            logout();
        }
    };
    const idleTimer = useIdleTimer({
        onIdle,
        startOnMount: false,
        startManually: true,
        stopOnIdle: true,
        timeout: 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120)
    });
    const onSessionDialogClose = () => setSessionExpired(false); // close the session dialog
    const getDefaultStep = (currentUser) => (currentUser ? 0 : -1);
    const updateStepState = (stepKey, stepState) => {
        dispatchStepState({ type: "UPDATE", payload: { stepKey, stepState } });
    };
    const createNewRequest = () => {
        dispatchStepState({ type: "RESET" });
        resetRequest();
        setUseSearchParams(true);
        logout(); // logout the user to allow for the active step to be Login
        setActiveStep(-1);
        history.replace({ search: "" }); // clear query parameters
    };
    const updateContactInformation = useCallback((currentUser) => {
        if(currentUser) { // update with user info
            dispatchStepState({ 
                type: "UPDATE", 
                payload: {
                    stepKey: stepKeys.CONTACT_INFORMATION,
                    stepState: { values: pick(currentUser, ["email", "firstName", "lastName"]), isComplete: true }
                }
            });
        } else {
            dispatchStepState({ type: "RESET" }); // reset all state since some options don't apply to unauthenticated users
        }
    }, [dispatchStepState]);
    const onFileUpload = (file) => {
        const email = stepState[stepKeys.CONTACT_INFORMATION].values.email;
        const uploadName = encodeURIComponent(`HELP_OSU-${email}-${new Date().getTime()}-${file.name}`);
        uploadFile(file, uploadName);
    };

    // when the page is reloaded from logout, navigate to the appropriate step based on the location hash
    useEffect(() => {
        if(history.action === "POP" && location.hash) {
            const stepKey = (location.hash.startsWith("#") ? location.hash.slice(1) : location.hash);
            const step = steps.find(({ key }) => (key === stepKey));
            if(step && activeStep !== step.index) setActiveStep(step.index);
        }
    }, [activeStep, history.action, location.hash, setActiveStep]);

    // when the user changes, set the active step, update the contact information, and indicate whether the idle timer should be started
    useEffect(() => {
        if(previousUser?.osuid !== user?.osuid) {
            setActiveStep(getDefaultStep(user));
            updateContactInformation(user);
            setStartIdleTimer((user ? true : false));
        }
    }, [updateContactInformation, user, previousUser?.osuid, setActiveStep]);

    // when the idle timer should be started, start the idle timer
    useEffect(() => {
        if(startIdleTimer === true && isIdleTimerStarted === false && isSessionExpired === false) {
            idleTimer.start();
            setIdleTimerStarted(true);
        }
    }, [idleTimer, isIdleTimerStarted, isSessionExpired, startIdleTimer]);

    // when the session expires, indicate that the idle timer is stopped and reset the idle timer
    useEffect(() => {
        if(isSessionExpired === true && isIdleTimerStarted === true) {
            setIdleTimerStarted(false);
            idleTimer.reset();
        }
    }, [idleTimer, isIdleTimerStarted, isSessionExpired]);

    // when creating the request fails, clear the error when leaving the Review page
    useEffect(() => {
        if(hasRequestError === true && !isReviewStep) resetRequest();
    }, [hasRequestError, isReviewStep, resetRequest]);

    useEffect(() => {
        const newT  = currentStepDetails?.label ? `${currentStepDetails?.label} (Step ${(stepRoRenderAsActive + 1)} of ${numOfStepsToComplete})` : ""
        if(newT && (pageTitle !== newT)) {
            setPageTitle(newT)
        }

    }, [setPageTitle, stepRoRenderAsActive, currentStepDetails?.label, pageTitle])

    useEffect(() => {
        if(searchParamsProvided) {
            dispatchStepState({
                type: "SEARCH_PARMS",
                payload: {
                    salesforceRoutingId: searchParamState?.salesforceRoutingId, 
                    selectionMappingId: searchParamState?.selectionMappingId, 
                    subtopic: searchParamState?.subtopic, 
                    topic: searchParamState?.topic, 
                    unit: searchParamState?.unit, 
                    audience: searchParamState?.audience
                }
            })
        }
    }, [searchParamsProvided, searchParamState?.audience, searchParamState?.salesforceRoutingId, searchParamState?.selectionMappingId, searchParamState?.subtopic, searchParamState?.topic, searchParamState?.unit, searchParamErrors])

    useEffect(() => {
        if(!errorWasShown?.current && searchParamErrors?.length && currentStepDetails?.key === stepKeys.REQUEST) {
            setErrorProps({
                title: "Alert",
                message: (() => {
                    return (
                        <div>
                            The following options provided by the URL are not available:
                            <ErrorList errors={searchParamErrors} />
                        </div>
                    )
                })()
            });
            errorWasShown.current = true
        }
    }, [searchParamErrors, currentStepDetails?.key])

    const contactInformation = stepState[stepKeys.CONTACT_INFORMATION].values;
    const showLogoutButton = (![/* isPageLoading,  */hasPageError].includes(true) && user);
    let createLabelId = (step) => `active-form-step-${step}-label-id`
    const { isComplete: isStepComplete } = stepState[stepKey] ?? {};
    let stepContext = stepKey === stepKeys.REVIEW ? stepState : stepState[stepKey];
    const componentProps = { 
        fileUpload, login, resetFileUpload, setErrorProps, setFileUploadProgress, setUseSearchParams, stepKey, stepState: stepContext, updateStepState,
        uploadFile: onFileUpload, user, userStatus, useSearchParams, config, configStatus, requestStatus, elevation, validAudiences
    };

    return (
        <div>
            {errorProps && <ErrorAlert {...errorProps} /> }
            <Mui.Card display="flex"  elevation={elevation} className={classes.card}>
                <Mui.Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <Mui.Box display="flex" flexDirection="column">
                    <PageHeading variant="h3" />
                    <Mui.Typography component="h2" variant="subtitle1">Get help from Ohio State</Mui.Typography>
                    </Mui.Box>
                    <Mui.Box display="flex" flexWrap="wrap">
                        <Mui.Typography component="div" variant="body2" className={classes.userName}>
                            {!isEmpty(contactInformation) && `${contactInformation.firstName ?? ""} ${contactInformation.lastName ?? ""}`}
                            {showLogoutButton && ` | ` }
                        </Mui.Typography>
                        {showLogoutButton && <Mui.Button color="primary" className={classes.logoutButton} onClick={() => logout()}>Log Out</Mui.Button>}
                    </Mui.Box>
                </Mui.Box>
                {/* {isPageLoading &&
                    <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.status}>
                        <Mui.CircularProgress aria-describedby="pageLoading" />
                        <Mui.Typography id="pageLoading" variant="body1" aria-live="assertive">Loading, please wait...</Mui.Typography>
                    </Mui.Box>
                } */}
                {hasPageError &&
                    <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.status}>
                        <Mui.Typography variant="body1">There was an error loading the page.</Mui.Typography>
                        <Mui.Button variant="contained" color="primary" aria-label="Reload the Page" onClick={() => getConfig()}>Reload</Mui.Button>
                    </Mui.Box>
                }
                {(/* !isPageLoading &&  */!hasPageError) &&
                    <Fragment>
                        <Header checkStateByKey={(key) => stepState[key]} focusableId={focusableId} setActiveStep={setActiveStep} createLabelId={createLabelId} elevation={elevation} activeStep={stepRoRenderAsActive} numOfStepsToComplete={numOfStepsToComplete} isRequestResolving={isRequestResolving} isReviewStep={isReviewStep} isRequestComplete={isRequestComplete} errorSubmitting={hasRequestError}/>
                        <Mui.Box data-testid="pageLoading" display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={isRequestResolving ? classes.status : null}>
                            {isRequestResolving &&<Mui.CircularProgress aria-describedby="pageLoading" />  }
                            <Mui.Typography id="pageLoading" variant="body1" aria-live="assertive">{isRequestResolving && "Submitting request, please wait..."}</Mui.Typography>
                        </Mui.Box>
                        {!isRequestResolving &&
                            <Fragment>
                                <div className="margin-bottom-2" role="group" aria-labelledby={createLabelId(stepRoRenderAsActive)}>
                                    <Component focusableId={focusableId}  {...componentProps} />
                                </div>
                                <Mui.Box flexDirection={isRequestComplete ? "row" : "row-reverse"} display="flex" justifyContent="space-between" marginTop="auto">
                                {isRequestComplete 
                                    ? <Mui.Button color="primary" onClick={createNewRequest}>Create a New Request</Mui.Button> 
                                    : <Fragment>
                                            {
                                                isReviewStep 
                                                    ? <Mui.Button variant="contained" color="primary" onClick={() => submitRequest(stepState, user)}>Submit</Mui.Button> 
                                                    : (stepIndex > -1) &&
                                                    <Mui.Button disabled={!isStepComplete || isFileUploading} variant="outlined" color="primary" onClick={() => {
                                                        setActiveStep((existingStepToActivate) => {
                                                            let stepToActivate = (numOfStepsToComplete === existingStepToActivate) ? existingStepToActivate : existingStepToActivate + 1
                                                            setFocusableId(createLabelId(stepToActivate))
                                                            return stepToActivate
                                                        })
                                                    }}>
                                                        Continue
                                                    </Mui.Button>
                                            }
                                            {
                                            (stepIndex >= 0) &&
                                                <Mui.Button color="primary" onClick={() => {
                                                    setActiveStep((existingStepToActivate) => {
                                                        const stepToActivate = existingStepToActivate - 1
                                                        setFocusableId(createLabelId(stepToActivate))
                                                        return stepToActivate
                                                    })
                                                }}>Back</Mui.Button>
                                            }
                                        </Fragment>
                                    }
                                </Mui.Box>
                            </Fragment>
                        }

                    </Fragment>
                }
            </Mui.Card>
            <Mui.Dialog open={isSessionExpired} onClose={onSessionDialogClose} aria-labelledby="sessionDialogTitle" aria-describedby="sessionDialogText">
                <Mui.DialogTitle id="sessionDialogTitle">Session Expired</Mui.DialogTitle>
                <Mui.DialogContent dividers>
                    <Mui.DialogContentText id="sessionDialogText">Your session has expired.</Mui.DialogContentText>
                </Mui.DialogContent>
                <Mui.DialogActions>
                    <Mui.Button variant="outlined" color="primary" onClick={onSessionDialogClose}>Close</Mui.Button>
                </Mui.DialogActions>
            </Mui.Dialog>
        </div>
    );
}