import { API } from "aws-amplify";
import apiConfig from "../api-config";
import { AUTH_MODE } from "./constants";

export function buildAction(type, payload) {
  return { type, payload };
}

export function getGraphQLAuthMode(state) {
  return state?.authentication?.user
    ? AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    : AUTH_MODE.AWS_IAM;
}

export function omitReduxProps(props = {}) {
  const clonedObject = Object.assign({}, props);
  delete clonedObject.history;
  delete clonedObject.location;
  delete clonedObject.match;
  delete clonedObject.staticContext;
  delete clonedObject.dispatch;

  return clonedObject;
}

export function isUnauthenticated(error) {
  const status =
    error?.response && error?.response?.status ? error.response.status : null;
  const unauthorizedErrors = [
    "The user is not authenticated",
    "No current user",
    "Request failed with status code 401",
  ];

  return unauthorizedErrors.includes(error) || status === 401;
}

export async function graphql(body) {
  const endpoint = process.env.REACT_APP_API_URL;
  
  const triggerWaf =
  process.env.REACT_APP_WEB_ACL_INTEGRATION_DISABLED !== "true";
  
  if (triggerWaf) {
      console.log('document.cookie', document.cookie)
    let headers = {
        "Content-Type": "application/json",
    }
    try {
        const { Authentication, Authorization } = await apiConfig.API.graphql_headers();
        if(Authentication) headers.Authentication = Authentication
        if(Authorization) headers.Authorization = Authorization        
    } catch (error) {
        console.error("Error", error)
    }

    return await window.AwsWafIntegration.fetch(endpoint, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(body),
    });
  }
  return await API.graphql(body);
}
