/* import LogIn from "../Authentication/components/LogIn";
import LogOut from "../Authentication/components/LogOut";
import Unauthorized from "./views/Unauthorized"; */
import Form from "../Form/containers";
const PATHS = [
    { path: "/", Component: Form }
];

const authenticationFlowPaths = [
    /* { path: "/login", Component: LogIn },
    { path: "/logout", Component: LogOut },
    { path: "/unauthorized", Component: Unauthorized } */
]

export {
    authenticationFlowPaths,
    PATHS as default
}