import React from "react";
import { Link } from "react-router-dom";
import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => {
    return {
        title: {
            fontSize: "1.5rem",
            fontWeight: 500,
            marginBottom: "1rem"
        }
    };
});

export default function PageNotFound() {
    const classes = useStyles();
    
    return (
        <div>
            <Mui.Typography variant="h2" className={classes.title}>Page Not Found</Mui.Typography>
            Return to the <Link to="/">Home</Link> page.
        </div>
    );
}