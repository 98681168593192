import React, { Fragment } from "react";
import { Mui } from "@osu/react-ui";
import useStyles from "../../styles";
import { ACTION_STATUS } from "../../../util/constants";

export default function Login(props = {}) {
    const { login, userStatus } = props;
    const classes = useStyles();
    const url = new URL(window.location);
    const code = url.searchParams.get("code");
    const isLoggingIn = (code || userStatus === ACTION_STATUS.LOADING);
    return (
        <Fragment>
            {isLoggingIn ?
                (<Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.status}>
                    <Mui.CircularProgress aria-describedby="loggingIn" />
                    <Mui.Typography id="loggingIn" variant="body1" aria-live="assertive">Logging in, please wait...</Mui.Typography>
                </Mui.Box>) :
                (<Mui.Box display="flex" flexDirection="column" alignItems="center">
                    <Mui.Box fontWeight="bold">We will need basic contact information to best help you.</Mui.Box>
                    <Mui.Box display="flex" flexDirection="column" flexWrap="wrap" alignItems="center" marginTop="2.5rem">
                        <Mui.Box alignSelf="center" display="flex">Login to use your Ohio State contact information</Mui.Box>
                        <Mui.Button variant="contained" color="primary" className={classes.osuLoginButton} onClick={() => login(true)}>OSU Login</Mui.Button>
                    </Mui.Box>
                    <Mui.Box display="flex" flexDirection="column" marginTop="1.5rem">
                        <Mui.Box alignSelf="center" display="flex">- OR -</Mui.Box>
                        <Mui.Button variant="outlined" color="primary" className={classes.noLoginButton} onClick={() => login()}>Proceed Without Login</Mui.Button>
                    </Mui.Box>
                </Mui.Box>)
            }
        </Fragment>
    );
}