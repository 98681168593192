import useStyles from "../styles";

const ErrorList = (props) => {
  const { errors, ...rest } = props;
  const classes = useStyles({});

  return (
    <ul className={classes.errorList} {...rest}>
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  );
};

export default ErrorList