import { API, Auth } from "aws-amplify";
import axios from "axios";
import { transformRequest } from "./transform";
import { SUBMIT_REQUEST, FILE_UPLOAD, FILE_UPLOAD_RESET, SET_USER, USER_STATUS, resetState } from "../actions-index";
import { ACTION_STATUS, FILE_TYPES } from "../util/constants";
import { buildAction, getGraphQLAuthMode } from "../util/functions";
import { redirectToLogOut } from "../util/login";
import * as Mutations from "../graphql/mutations";
import * as Queries from "../graphql/queries";

export function logout(search, hash) {
    return async (dispatch) => {
        (await Auth.currentAuthenticatedUser()).signOut();
        dispatch(resetState(SET_USER, null));
        dispatch(resetState(USER_STATUS, ""));
        redirectToLogOut(search, hash);
    };
}

export function uploadFile(file, uploadName) {
    return async (dispatch, getState) => {
        try {
            dispatch(buildAction(FILE_UPLOAD, { progress: 10, status: ACTION_STATUS.LOADING }));

            const response = await API.graphql({ authMode: getGraphQLAuthMode(getState()), query: Queries.getSignedUploadUrl, variables: { name: uploadName } });
            const { s3Object, signedUrl } = response.data.getSignedUploadUrl;
            dispatch(buildAction(FILE_UPLOAD, { progress: 40 }));

            await axios.put(signedUrl, file, { headers: { 'Content-Type': file.type } });
            dispatch(buildAction(FILE_UPLOAD, { progress: 75 }));

            file.id = file.name; // file uploader needs an id to generate file chips
            file.s3Object = s3Object; // used for submit
            dispatch(buildAction(FILE_UPLOAD, { file: file, progress: 100, status: ACTION_STATUS.SUCCESS }));
        } catch (error) {
            console.error("File Upload Error: ", error);
            dispatch(buildAction(FILE_UPLOAD, { progress: 0, status: ACTION_STATUS.ERROR }));
        }
    };
}

export function setFileUploadProgress(progress) {
    return async (dispatch) => {
        dispatch(buildAction(FILE_UPLOAD, { progress }));
    };
}

export function resetFileUpload() {
    return async (dispatch) => {
        dispatch(buildAction(FILE_UPLOAD_RESET, {}));
    };
}

export function submitRequest(stepState) {
    return async (dispatch, getState) => {
        try {
            dispatch(buildAction(SUBMIT_REQUEST, { status: ACTION_STATUS.LOADING }));
            const variables = transformRequest(stepState);
            const state = getState();
            const userId = state?.authentication?.user?.osuid;
            if (userId) variables.input.emplid = userId;
            const file = state?.fileUpload?.file;
            if (file) {
                const type = FILE_TYPES[file.type];
                if(!type) throw new Error(`Invalid file type${(file.type ? ` "${file.type}"` : "")}`);
                const fileName = file.name.replace("-UPLOAD-PENDING.pdf", ""); // added by the EIP Common API
                const lastPeriodIndex = fileName.lastIndexOf(".");
                const extension = (lastPeriodIndex === -1 ? null : fileName.slice(lastPeriodIndex + 1));
                if(!extension) throw new Error("File is missing extension");
                variables.input.file = {
                    contentType: file.type,
                    extension,
                    name: file.name,
                    s3Object: file.s3Object,
                    type
                };
            }
            const submit = async () => (await API.graphql({ authMode: getGraphQLAuthMode(state), query: Mutations.submitRequest, variables }));
            const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
            let iteration = 0, maxAttempts = 3, intervalSeconds = 5, backOffRate = 1.5;
            let success = false;
            do {
                try {
                    if(iteration !== 0) console.log(`Retry ${iteration}`);
                    const response = await submit();
                    success = true;
                    dispatch(buildAction(SUBMIT_REQUEST, { status: ACTION_STATUS.SUCCESS, data: response?.data?.submitRequest }));
                } catch (error) {
                    if(error.errors?.[0]?.message === "Network Error" && iteration !== maxAttempts) { // retry for network errors
                        if(iteration !== 0) intervalSeconds = (intervalSeconds * backOffRate);
                        console.log(`Network error.  Waiting ${intervalSeconds} seconds before retry.`);
                        await wait((intervalSeconds * 1000));
                        iteration++;
                    } else {
                        throw error;
                    }
                }
            } while (success === false && (iteration <= maxAttempts));
        } catch (error) {
            console.error("Submit Request Error: ", error);
            dispatch(buildAction(SUBMIT_REQUEST, { status: ACTION_STATUS.ERROR }));
        }
    };
}