import { SUBMIT_REQUEST, FILE_UPLOAD, FILE_UPLOAD_RESET, UPDATE_USER_OPTIONS, CLEAR_USER_OPTIONS } from "../actions-index";

export function request(state = {}, action = {}) {
    return (action.type === SUBMIT_REQUEST ? action.payload : state);
}

export function fileUpload(state = {}, action = {}) {
    switch(action.type)  {
        case FILE_UPLOAD:
            return { ...state, ...action.payload };
        case FILE_UPLOAD_RESET:
            return {};

        default:
            return state;
    }
}

export function audiences(state = {}, action = {}) {
    let newOptions = { ...state, ...action?.payload ?? {} }
    
    switch(action.type)  {
        case UPDATE_USER_OPTIONS:
            return newOptions;
        case CLEAR_USER_OPTIONS:
                return { ...state, ...action.payload };
        default:
            return state;
    }
}