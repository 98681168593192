export const SUBMIT_REQUEST = 'SUBMIT_REQUEST';
export const FETCH_USER = 'FETCH_USER';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_RESET = 'FILE_UPLOAD_RESET';
export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const CONFIG = 'CONFIG';
export const UPDATE_USER_OPTIONS = 'UPDATE_USER_OPTIONS';
export const CLEAR_USER_OPTIONS = 'CLEAR_USER_OPTIONS';

/* Export each action file below */
export * from './App/actions';
export * from './Authentication/actions';
export * from './Form/actions';