
import { isArray, mergeWith } from "lodash";
import Login from "./components/Steps/Login";
import ContactInformation from "./components/Steps/ContactInformation";
import Request from "./containers/Request";
import Review from "./components/Steps/Review";

export const stepKeys = {
    LOGIN: "LOGIN",
    CONTACT_INFORMATION: "CONTACT_INFORMATION",
    REQUEST: "REQUEST",
    REVIEW: "REVIEW"
};

export const steps = [
    {
        key: stepKeys.LOGIN,
        index: -1,
        label: "Login",
        Component: Login
    },
    {
        key: stepKeys.CONTACT_INFORMATION,
        index: 0,
        label: "Contact Information",
        Component: ContactInformation
    },
    {
        key: stepKeys.REQUEST,
        index: 1,
        label: "Request",
        Component: Request
    },
    {
        key: stepKeys.REVIEW,
        index: 2,
        label: "Review",
        Component: Review
    }
];

export function stepReducer(state, action) {
    const newState = Object.assign({}, state);
    const { payload, type } = action;
    const requestInitialized = Object.prototype.hasOwnProperty.call(newState?.[stepKeys.REQUEST], 'values')
    const allowSearchParms = requestInitialized
        && (
            !Object.keys(newState?.[stepKeys.REQUEST]?.values).length 
            || newState?.[stepKeys.REQUEST]?.values?.FAILURE
        )
    switch (type) {
        case "RESET":
            return getInitialStepState();
        case "UPDATE":
            if(payload.stepKey) {
                newState[payload.stepKey] = payload.stepState
            }
            return newState;
        case "SEARCH_PARMS":
            if(allowSearchParms) {
                newState[stepKeys.REQUEST].values =
                    payload?.audience 
                        ? payload 
                        : { 
                            FAILURE: true
                        }
            } 
            return newState
        default:
            return state;
    }
}

export function getInitialStepState() {
    const initialStepState = {};
    steps.forEach(step => {
        initialStepState[step.key] = { values: {}, isComplete: false };
    });
    return initialStepState;
}

export function getUpdatedStepStateValues(stepState, fieldName, fieldValue, parentFieldName = null) {
    let update = { [fieldName]: fieldValue };
    update = (parentFieldName ? { [parentFieldName]: update } : update);
    return mergeWith(stepState.values, update, 
        // customizer to handle array differences
        (object, source) => {
          if(isArray(object) && isArray(source) && object.length !== source.length) return source;
        }
    );
}

export function getStep(activeStep) {
    let step = steps.find(({ index }) => (index === activeStep));
    if(activeStep === -1) {
        const loginStep = Object.assign({}, step)
        return {
            ...(steps.find(({ index }) => index === 0)),
            Component: loginStep.Component
        }
    } 
    return step
}

export const numOfStepsToComplete = steps.filter(({ index }) => index >= 0).length;
