import { Auth } from 'aws-amplify';

export function redirectToLogIn(redirectPath = ''){
    const { oauth } = Auth.configure();
    let path = redirectPath
    
    if(!!window.location.pathname && !path) {
        path = window.location.pathname
        if(window.location.search) {
            path += window.location.search
        }
    }

    Auth.federatedSignIn({
        provider: oauth.idp,
        customState: (path || '/')
    });
}

export function redirectToLogOut(search = "", hash = "") {
    const { userPoolWebClientId, oauth } = Auth.configure();
    window.location.assign(`https://${oauth.domain}/logout?&client_id=${userPoolWebClientId}&logout_uri=${oauth.redirectSignOut}${search}${hash}`);
}