import * as Cookies from 'js-cookie';

var CustomAuthStorage = function () {

  function CustomAuthStorage(data) {

    this.domain = data.domain;

    if (data.path) {
      this.path = data.path;
    } else {
      this.path = '/';
    }

    //session cookie is default
    if (Object.prototype.hasOwnProperty.call(data, 'expires')) {
      this.expires = data.expires;
    }

    if (Object.prototype.hasOwnProperty.call(data, 'secure')) {
      this.secure = data.secure;
    } else {
      this.secure = true;
    }
  }

  CustomAuthStorage.prototype.setItem = function setItem(key, value) {
    Cookies.set(key, value, {
      path: this.path,
      expires: this.expires,
      domain: this.domain,
      secure: this.secure
    });
    return Cookies.get(key);
  };

  CustomAuthStorage.prototype.getItem = function getItem(key) {
    return Cookies.get(key);
  };


  CustomAuthStorage.prototype.removeItem = function removeItem(key) {
    return Cookies.remove(key, {
      path: this.path,
      domain: this.domain,
      secure: this.secure
    });
  };


  CustomAuthStorage.prototype.clear = function clear() {
    var cookies = Cookies.get();
    var index = void 0;
    for (index = 0; index < cookies.length; ++index) {
      Cookies.remove(cookies[index]);
    }
    return {};
  };

  return CustomAuthStorage;
}();

export default CustomAuthStorage;